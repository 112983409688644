<template>
  <div>
    <div class="select">
<!--      <label for="countrySelect">{{ $t('events.page.country') }}</label>-->
      <select
        v-model="country_id"
        class="select-text"
        id="countrySelect"
        :placeholder="$t('contacts.popups.editContact.placeholders.country')"
        full-width
        filterable
        @change="companyCountryChangeHandler($event)"
      >
        <option
          v-for="(country, index) in countries"
          :key="'country_city_fields_country_' + index"
          :label="country.name"
          :value="country.id"
          :selected="selectedCountryId === country.id"
        >
          {{ country.name }}
        </option>
      </select>

      <span class="select-highlight" />
      <span class="select-bar" />
      <label class="select-label">
       {{ $t('events.page.country') }}
      </label>
    </div>

    <div class="select">
<!--
      <label for="citySelect">
        {{ $t('events.page.city') }}
      </label>
      -->
      <select
        class="select-text"
        id="citySelect"
        v-model="city_id"
        :placeholder="$t('contacts.popups.editContact.placeholders.city')"
        full-width
        filterable
        :style="{
          'opacity' : cities.length ? 1 : 0
        }"
        @change="companyCityChangeHandler($event)"
      >
        <option
          v-for="(city, index) in cities"
          :key="'country_city_fields_city_' + index"
          :label="city.name"
          :value="city.id"
          :selected="selectedCityId === city.id"
        >
          {{ city.name }}
        </option>
      </select>
      <span class="select-highlight" />
      <span class="select-bar" />
      <label class="select-label">
        {{ $t('events.page.city') }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'countryCityFields',
  props: {
    countries: {
      type: Array,
      default: () => []
    },
    cities: {
      type: Array,
      default: () => []
    },
    selectedCountryId: {
      type: Number,
      default: 0
    },
    selectedCityId: {
      type: Number,
      default: 0
    },
  },
  watch: {
    selectedCountryId: {
      immediate: true,
      handler() {
        this.localSelectedCountryId = this.selectedCountryId;
      }
    },
    selectedCityId: {
      immediate: true,
      handler() {
        this.localSelectedCityId = this.selectedCityId;
      }
    }
  },
  computed: {
    country_id: {
      get() {
        return this.localSelectedCountryId;
      },
      set(newVal) {
        this.localSelectedCountryId = newVal;
      }
    },
    city_id: {
      get() {
        return this.localSelectedCityId;
      },
      set(newVal) {
        this.localSelectedCityId = newVal;
      }
    },
  },
  data: function () {
    return {
      localSelectedCountryId: 0,
      localSelectedCityId: 0,
    }
  },
  methods: {
    companyCountryChangeHandler() {
      this.city_id = 0;
      this.$emit('countryChanged', {
        country: this.country_id,
        city: this.city_id,
      })
    },
    companyCityChangeHandler() {
      this.$emit('countryChanged', {
        country: this.country_id,
        city: this.city_id,
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/_styles/base/custom-select";

.mu-input,
.mu-input-label {
  font-size: 1.4rem;
}

.layout-edit-company {
  padding-top: 4rem;
}

.layout-inline {
  display: grid;
  grid-template-columns: 50fr 50fr;
  grid-column-gap: 6.6rem;

  @media (max-width: $ew-tablet-width-breakpoint) {
    grid-template-columns: 100fr;
    gap: 20px;
  }
}
</style>
