<template>
  <div class="upload-files">
    <div class="block-title">
      <slot></slot>
    </div>
    <div class="file-wrapper">
      <mu-paper
        class="file-block-paper"
        v-for="(file, index) in files"
        :key="index"
        :z-depth="0"
      >
        <div
          class="file-block"
          v-if="files[index]"
        >
          <div
            class="preview-blurred"
            v-if="isImage(file)"
            :style="{
              backgroundImage: 'url(' + file.url + ')',
            }"
          />
          <div
            class="preview-blurred"
            v-else
          />
          <div class="preview-dimmer" />
          <div
            class="preview-contain"
            v-if="isImage(file)"
            :style="{
              backgroundImage: 'url(' + file.url + ')',
            }"
          >
          </div>
          <div
            class="preview-contain"
            v-else
          >
            <div class="file-icon">
              <div
                v-if="file.extension"
                class="file-extension"
              >
                {{ file.extension }}
              </div>
            </div>
          </div>
          <div class="actions">
            <div
              class="action delete"
              @click="removeFiles(index, file.id)"
            >
              <icon-square-delete />
            </div>
          </div>
          <div class="file-meta">
            <div
              class="filename"
              v-if="files[index].name || files[index].filename"
              :class="{
                'filename-long' : files[index].nameClean.length > 15,
              }"
            >
              {{ files[index].nameClean }}
            </div>
            <div
              class="fileext"
              v-if="files[index].extension"
            >
              <span v-if="files[index].extension">
                {{ files[index].extension }}
              </span>
            </div>
          </div>
        </div>
      </mu-paper>
      <div
        class="file-block-paper"
        v-if="loadingFiles"
      >
        <div class="file-block file-block-progress">
          <div class="sizer" />
          <div class="preview-contain">
            <ew-circular-loading :size="18" />
          </div>
        </div>
      </div>
      <div
        class="file-block-paper"
        v-else
      >
        <div class="file-block file-block-upload">
          <div class="sizer" />
          <div class="preview-contain">
            <div class="uploader-icon">
              <icon-plus />
            </div>
            <input
              class="upload-input"
              type="file"
              @change="onFileChange"
            />
          </div>
        </div>
      </div>
    </div>
    <span
      v-if="errors.userFileError"
      class="error"
    >
      {{$t(errors.userFileError)}}
    </span>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconPlus from '@/_modules/icons/components/icon-plus.vue';

export default {
  // TODO: rewrite or remove this whole file
  name: 'upload-files',
  components:{
    IconSquareDelete,
    IconPlus,
  },
  computed: {
    ...mapState('uploadImageStore', ['file', 'loading', 'loadingFiles', 'uploadedFile']),
    ...mapState({
      userFileError: state => state.uploadImageStore.userFileError,
    })
  },
  props: {
    files: {
      type: Array,
      default: function () {
        return [];
      }
    },
    deleteRoute: {
      immediate: true,
      type: String,
      default: 'user',
    },
    externalId: {
      default: -1
    }
  },
  watch: {
    files: {
      deep: true,
      handler() {
        if (!this.files) {
          return;
        }
        // create nameClean, fileext for design
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          if (!file.filename) {
            continue;
          }
          let fileExt = (file.filename.indexOf('.') > -1 ? file.filename.split('.').pop() : '');
          let nameClean = (fileExt ? file.filename.replace('.' + fileExt, '') : file.filename)
          if (typeof file.nameClean == 'undefined') {
            this.files[i].nameClean = nameClean;
          }
          if (fileExt) {
            this.files[i].extension = fileExt;
          }
        }
        this.formData.files = this.files;
      }
    },
    userFileError() {
      if (this.userFileError.code) {
        this.setError({message_key: 'errors.validation.file_upload_failed'});
      } else {
        this.clearErrorsAndFileSizeInfo();
      }
    },
  },
  data: () => ({
    fileSizeInfo: null,
    filesType: null,
    errors: {
      files: '',
      userFileError: '',
    },
    formData: {
      files: []
    }
  }),
  mounted() {
    if (this.files) {
      this.formData.files = this.files;
    }
  },
  methods: {
    isImage(file) {
      if (!file || !file.url) {
        return false;
      }

      const whiteList = {
        jpg: true,
        png: true,
        gif: true,
        jpe: true,
        jpeg: true,
        svg: true
      };

      const ext = (file.url || '').split('.').pop().toLowerCase();
      return Object.prototype.hasOwnProperty.call(whiteList, ext);
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files || !files.length) {
        return;
      }
      this.createFile(files[0]);
    },
    createFile(file) {
      const reader = new FileReader();

      reader.onload = async () => {
        this.filesType = file.type;
        this.initFileSizeInfo(file.size, false);
        if (!this.isFileValid()) {
          return;
        }

        await this.$store.dispatch('uploadImageStore/uploadUserFile', file);

        const fileExt = file.name.indexOf('.') > -1 ? file.name.split('.').pop() : '';
        // Backend has time limit of N sec, after which it closes the connection
        if (!this.uploadedFile.url) {
          this.setError({message_key: 'errors.validation.connection_timed_out'});
          return;
        }
        const fileObj = {
          url: this.uploadedFile.url,
          name: file.name,
          nameClean: (fileExt ? file.name.replace('.' + fileExt, '') : file.name),
          extension: fileExt,
          size: this.uploadedFile.size,
          type: this.uploadedFile.type
        };
        this.formData.files.push(fileObj);
        this.$emit('filesData', this.formData.files)
      }

      reader.readAsDataURL(file);
    },
    initFileSizeInfo(number) {
      let result;
      if (number < 1024) {
        result = number + 'bytes';
      } else if (number >= 1024 && number < 1048576) {
        result = (number / 1024).toFixed(1) + 'KB';
      } else if (number >= 1048576) {
        result = (number / 1048576).toFixed(1) + 'MB';
      }

      this.fileSizeInfo = {
        sizeText: result,
        rawSize: number
      };
    },
    removeFiles(index, fileId) {
      let actionName = 'userStore/deleteFile';
      let payload = {id: fileId};
      switch (this.deleteRoute) {
        case 'promoPage':
          actionName = 'promoStore/deleteFileUrlPromoPage';
          payload = {file_id: fileId, event_id: this.$route.params.eventId, external_id: this.externalId};
          break;
        case 'user':
          payload = {id: fileId};
          break;
        case 'contact':
          payload = {file_id: fileId, event_id: Number(this.$route.params.eventId)};
          actionName = 'contactsStore/deleteContactFile';
          break;
        default:
          actionName = 'userStore/deleteFile';
      }
      if (fileId) {
        this.$store.dispatch(actionName, payload);
      } else {
        this.$store.dispatch('uploadImageStore/clearUserFileError');
      }
      this.formData.files.splice(index, 1);
    },
    isFileValid() {
      if (this.isFileTooLarge()) {
        this.setError({message_key: 'errors.validation.file_size_too_large'});
        this.fileSizeInfo = null;
      } else if (!this.isFileUploaded()) {
        this.setError({message_key: 'errors.validation.file_upload_failed'});
      } else {
        this.clearErrorsAndFileSizeInfo();
        return true;
      }
      return false;
    },
    isFileTooLarge() {
      return this.fileSizeInfo && this.fileSizeInfo.rawSize > 50 * 1024 * 1024;
    },
    isFileUploaded() {
      const isFile = (this.filesType && this.filesType.split('/')) || null;
      return isFile && isFile[0];
    },
    setError(err) {
      this.errors.files = err.message_key;
      this.errors.userFileError = err.message_key;
    },
    clearErrorsAndFileSizeInfo() {
      this.errors.files = null;
      this.errors.userFileError = null;
      this.fileSizeInfo = null;
    },
  }
}

</script>
<style scoped lang="scss">
  .paper {
    padding: 20px 0;
    text-align: center;
  }

  .block {
    &-title {
      color: rgba(0, 0, 0, 0.38);
      margin-bottom: 1rem;
    }
  }

  .upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.001;
    z-index: 5;
    cursor: pointer;
  }

  .error {
    color: #dd4b39;
    text-align: left;
  }

  .button-wrapper {
    text-align: center;

    .mu-button {
      margin: 8px 8px 8px 0;
      vertical-align: middle;
    }

    a.mu-button {
      text-decoration: none;
    }
  }

  .file-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
  }

  .file-block-paper {
    border-radius: 0;
    overflow: visible;
  }

  .file-block {
    position: relative;
    width: 60px;
    margin: 0 60px 64px 0;
    border-radius: 7px;

    .preview-blurred,
    .sizer {
      padding-bottom: 100%;
      background-size: 300%;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      z-index: 1;
      border-radius: 7px;
      overflow: hidden;
    }

    .preview-dimmer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $ew-black;
      opacity: 0.25;
      border-radius: 7px;
      overflow: hidden;
    }

    .preview-contain {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 7px;
      overflow: hidden;
    }

    .actions {
      position: absolute;
      right: -31px;
      top: 0;
      height: 100%;
      display: flex;

      .action {
        margin-right: 1.6rem;
        justify-content: center;
        display: flex;
        flex-flow: column wrap;

        svg {
          width: 24px;
          height: 24px;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: 1;
        }

        &.edit {
          color: #57c7ff;
        }

        &.delete {
          color: #f77;
        }
      }
    }

    .file-meta {
      position: absolute;
      top: 100%;
      left: 0;
      padding-top: 18px;
      font-size: 1.2rem;
      cursor: pointer;
      width: 100%;

      .filename {
        position: absolute;
        top: 9px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 140%;
        overflow: hidden;
        font-size: 12px;
        text-align: center;
        white-space: nowrap;

        &-long {
          text-align: left;
        }
      }

      .fileext {
        opacity: 0.3;
        text-align: center;
      }
    }

    &-upload {
      background-color: #f2f2f2;
      margin-right: 0;

      .mu-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }
    }

    &-progress {
      background-color: #f2f2f2;

      .ew-circular-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: -8px 0 0 -8px;
      }
    }

    .title {
      overflow: hidden;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }

  .file-block-paper:last-child .file-block {
    margin-right: 0;
  }

  .flex-container {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
  }

  .button {
    color: $ew-white;
    background-color: #3369ff;
  }

  .file-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 30px;
    transform: translate(-50%, -50%);
    background-color: $ew-white;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0 5px 0 0;
  }

  .file-icon::before,
  .file-icon::after {
    content: "";
    position: absolute;
    background-color: #ccc;
  }

  .file-icon::before {
    width: 1px;
    height: 5px;
    top: 0;
    right: 5px;
  }

  .file-icon::after {
    width: 5px;
    height: 1px;
    top: 5px;
    right: 0;
  }

  .file-icon .file-extension {
    background-color: #777;
    position: absolute;
    bottom: 5px;
    right: -5px;
    color: $ew-white;
    padding: 1px 2px;
    font-size: 9px;
    line-height: 1;
  }

  .uploader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: translate(-50%, -50%);

    /deep/ svg {
      width: 16px;
      height: 16px;
    }
  }
</style>
