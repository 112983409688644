



































































import Vue from 'vue';
import AuthPhoneInputHelper, { TCountryDataRow } from '@/_helpers/auth-phone-input.helper';
// @ts-ignore
import InputMask from 'vue-input-mask'; // TODO: add types or choose another plugin

interface IPhoneInputData {
  phoneNumberCountryCode: string;
  phoneNumberNumber: string;
  countryName: string;
  isCountriesListShown: boolean;
  countrySearchString: string;
  countryList: TCountryDataRow[];
  phoneMask: string;
}

interface IPhoneInputMethods {
  showCountriesList: () => void;
  hideCountriesList: () => void;
  optionSearch: () => void;
  countryClick: (countryData: TCountryDataRow) => void;
  getDataName: (countryData: TCountryDataRow) => string;
  focusOptionSearch: () => void;
  getCountryNameTranslated: (countryData: TCountryDataRow) => string;
  chooseCountryByPhoneCode: (event: KeyboardEvent) => void;
  cleanUpPhoneCode: () => void;
  checkEmitPhoneNumber: () => void;
}

type TMaskedInputComponent = {
  getInputDOMNode: () => HTMLInputElement;
}

interface IPhoneInputComputed {
  isPhoneNumberInputEnabled: boolean;
  maskedInputComponent: TMaskedInputComponent;
}

type TMaskedInputProps = {
  searchSelector: string;
  default?: string;
  clearInput?: boolean;
}

const PhoneInput = Vue.extend<IPhoneInputData, IPhoneInputMethods, IPhoneInputComputed, TMaskedInputProps>({
  name: 'phone-input',
  components: {
    InputMask
  },
  computed: {
    isPhoneNumberInputEnabled(): boolean {
      return !this.countryName;
    },
    maskedInputComponent(): TMaskedInputComponent {
      // TODO: avoid double type casting
      return this.$refs.phoneNumberNumberInput && ((this.$refs.phoneNumberNumberInput as unknown) as TMaskedInputComponent);
    }
  },
  watch: {
    phoneNumberNumber: {
      handler(): void {
        this.checkEmitPhoneNumber();
      }
    },
    clearInput: {
      handler(): void {
        if (this.clearInput === true) {
          this.phoneNumberNumber = '';
        }
      }
    }
  },
  props: {
    searchSelector: {
      type: String,
      default: '.auth-wrapper',
    },
    clearInput: {
      type: Boolean,
      default: false
    }
  },
  data(): IPhoneInputData {
    return {
      phoneNumberCountryCode: '+',
      phoneNumberNumber: '',
      countryName: '',
      isCountriesListShown: false,
      countrySearchString: '',
      countryList: AuthPhoneInputHelper.getCountryData(),
      phoneMask: '#########', // some default mask
    };
  },
  mounted() {
    document.addEventListener('click', this.hideCountriesList);
    this.cleanUpPhoneCode();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideCountriesList);
  },
  methods: {
    /* Emits a number to the parent if the number is found to be okayish.
     */
    checkEmitPhoneNumber(): void {

      if (this.countryName && this.phoneNumberCountryCode && this.phoneNumberNumber) {

        // removing phone code from the beginning of the number
        let preparedNumber = this.phoneNumberNumber.replace(/[^\d]/g, '');

        // joining the phone number into a +00000000000... string
        preparedNumber = this.phoneNumberCountryCode + preparedNumber;

        // Sending to the parent component
        this.$emit('phoneNumberReady', preparedNumber);
        return;

      }

      // Letting the parent component clear the phoneNumber
      this.$emit('phoneNumberReady', '');

    },
    cleanUpPhoneCode(): void {
      this.phoneNumberCountryCode = '+' + this.phoneNumberCountryCode.replace(/[^\d]/g, '').substr(0, 6);
    },

    /* Searches country in countryList using phone code
     * from keyup
     */
    chooseCountryByPhoneCode(event: KeyboardEvent): void {
      let result = false;

      /* Keyboard UX: If a user has pressed Enter and there is only one visible country,
       * use that country via a DOM click().
       */
      if (event.key.toLowerCase() === 'enter') {
        const searchString = this.countrySearchString.toLowerCase();
        const selector = this.searchSelector + ' .custom-select .custom-option[data-name*="' + searchString + '"]';
        const visibleOptions = document.querySelectorAll(selector);

        if ((visibleOptions as NodeListOf<Element>).length === 1) {
          (visibleOptions[0] as HTMLElement).click();
        }
        // TODO: list navigation with up and down arrows
      }

      this.cleanUpPhoneCode();

      for (let i = 0; i < this.countryList.length; i++) {
        if (this.countryList[i].phoneCode === this.phoneNumberCountryCode) {
          this.countryClick(this.countryList[i]);
          result = true;
          break;
        }
      }
      if (!result) {
        this.countryName = '';
      }
    },

    showCountriesList(): void {
      this.isCountriesListShown = true;
    },

    hideCountriesList(): void {
      this.isCountriesListShown = false;
    },

    /* Moves focus into ref="optionSearchInput"
     */
    focusOptionSearch(): void {
      this.showCountriesList();
      this.$nextTick(() => {
        if (!this.$refs.optionSearchInput) {
          return;
        }
        (this.$refs.optionSearchInput as HTMLElement).focus();
      });
    },

    /* CSS search among options */
    optionSearch(): void {
      const h = document.getElementsByTagName('head')[0];
      const styleId = 'authPhoneOptionSearcher';
      let selectors = this.searchSelector + ' .custom-select .custom-option';
      let searchStyles = document.getElementById(styleId);
      if (!searchStyles) {
        searchStyles = document.createElement('style');
        searchStyles.id = styleId;
        h.appendChild(searchStyles);
      }
      searchStyles.innerHTML = '';
      if (!this.countrySearchString) {
        return;
      }

      const searchString = this.countrySearchString.toLowerCase();

      // Exposing the selector for use in other places
      selectors = this.searchSelector + ' .custom-select .custom-option[data-name*="' + searchString + '"]';

      searchStyles.innerHTML = this.searchSelector + ' .custom-select .custom-option{ display: none; }';
      searchStyles.innerHTML += selectors + '{ display: block; }';

    },

    /* Actions on country click
     */
    countryClick(countryData: TCountryDataRow): void {
      this.phoneNumberCountryCode = countryData.phoneCode;
      this.hideCountriesList();
      this.countryName = this.getCountryNameTranslated(countryData);

      // update phoneMask
      this.phoneMask = countryData.maskNoCode;
      this.$emit('phoneMask', this.phoneMask + this.phoneNumberCountryCode);
      // clear the string
      this.countrySearchString = '';
      this.optionSearch();

      // focus into the phone field
      this.$nextTick(() => {
        if (!this.maskedInputComponent) {
          return;
        }
        const maskedInput = this.maskedInputComponent.getInputDOMNode();
        maskedInput && maskedInput.focus();
      });

    },

    /* Returns string to be used .custom-option data-name="[IN HERE]"
     * Returns all languages at the moment, because no other logic has been requested
     */
    getDataName(countryData: TCountryDataRow): string {
      return (countryData.phoneCode + countryData.name.en + countryData.name.ru).toLowerCase();
    },

    /* Gets translated country name from a country data object

     */
    getCountryNameTranslated(countryData: TCountryDataRow): string {
      let result = countryData.name.en; // English translation is default

      const pageLang = this.$i18n.locale;
      if (countryData.name[pageLang]) {
        result = countryData.name[pageLang];
      }
      return result;
    }
  }
});
export default PhoneInput;
