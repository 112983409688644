<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#00B6F8" fill-opacity="0.1"/>
    <path d="M24.2857 13.5714H12.1429C10.9594 13.5714 10 14.5308 10 15.7143V27.8571C10 29.0406 10.9594 30 12.1429 30H24.2857C25.4692 30 26.4285 29.0406 26.4285 27.8571V15.7143C26.4285 14.5308 25.4692 13.5714 24.2857 13.5714ZM25 27.8571C25 28.2516 24.6802 28.5714 24.2857 28.5714H12.1429C11.7484 28.5714 11.4286 28.2516 11.4286 27.8571V15.7143C11.4286 15.3198 11.7484 15 12.1429 15H24.2857C24.6802 15 25 15.3198 25 15.7143V27.8571Z" fill="#00B6F8"/>
    <path d="M27.8571 10H15C13.8166 10 12.8572 10.9594 12.8572 12.1429C12.8572 12.5374 13.177 12.8572 13.5715 12.8572C13.966 12.8572 14.2857 12.5374 14.2857 12.1429C14.2857 11.7484 14.6055 11.4286 15 11.4286H27.8571C28.2516 11.4286 28.5714 11.7484 28.5714 12.1429V25C28.5714 25.3945 28.2516 25.7143 27.8571 25.7143C27.4626 25.7143 27.1428 26.0341 27.1428 26.4286C27.1428 26.8231 27.4626 27.1429 27.8571 27.1429C29.0406 27.1429 30 26.1835 30 25V12.1429C30 10.9594 29.0406 10 27.8571 10Z" fill="#00B6F8"/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconCopy = Vue.extend({
  name: 'icon-copy',
});
export default IconCopy;
</script>
